/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import 'node_modules/@zonar-ui/sidenav/zonar-ui-sidenav.theme.scss';
@import '~material-icons/iconfont/material-icons.scss';
@import '~saturn-datepicker-ext/bundle.css';
$md-primary: (
    50 : #e5eaf1,
    100 : #becadd,
    200 : #93a6c6,
    300 : #6882af,
    400 : #47689e,
    500 : #274d8d,
    600 : #234685,
    700 : #1d3d7a,
    800 : #173470,
    900 : #0e255d,
    A100 : #92adff,
    A200 : #5f87ff,
    A400 : #2c61ff,
    A700 : #134eff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);
//.mat-select-panel {
//    max-height: 50vh !important;
//  }

.custom-dialog-container .mat-dialog-container {
  padding: 0;
}
.selectPanel {
  margin-top: 40px;
  .mat-active {
		background: initial !important;
		.mat-option-text {
      font-weight: bold;
			color: #0074cd;
		}
	}
}

* {
  font-family: OpenSans;
}

@import '~@angular/material/theming';
@import "../node_modules/@zonar-ui/material/zonar-ui-material.theme";
@import "../node_modules/@zonar-ui/date-picker/zonar-ui-date-picker.theme";
@import "../node_modules/@zonar-ui/hos-navigation/zonar-ui-hos-navigation.theme";
@import '../node_modules/@zonar-ui/core/zonar-ui-core.theme';
@import '../node_modules/@zonar-ui/hos-filters-container/zonar-ui-hos-filters-container.theme';
@import '../node_modules/@zonar-ui/hos-components/zonar-ui-hos-components.theme';
@import '../node_modules/@zonar-ui/footer/zonar-ui-footer.theme.scss';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat-palette($md-primary);
$app-accent:  mat-palette($mat-grey, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn:    mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

// Progress spinner pattern library theme has compile issue with the latest zonar-ui material library.
// So, instead of importing from there included the same mixin here.
@mixin zonar-ui-progress-spinner-theme($theme) {
  $primary:       map-get($theme, primary);
  $accent:        map-get($theme, accent);
  $warn:          map-get($theme, warn);
  $background:    map-get($theme, background);
  $foreground:    map-get($theme, foreground);

  .progress-spinner-container {
    .radar-fill {
        fill: get-mat-color($primary, default);

        &.accent {
            fill: get-mat-color($accent, default);
        }

        &.warn {
            fill: get-mat-color($warn, default);
        }
    }
  }
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@mixin apply-zonar-theme($theme) {
    @include angular-material-theme($theme);
    @include zonar-ui-date-picker-theme($theme);
    @include zonar-ui-progress-spinner-theme($theme);
    @include zonar-ui-hos-navigation-theme($theme);
    @include zonar-ui-core-theme($theme);
    @include zonar-ui-hos-filters-container-theme($theme);
    @include zonar-ui-hos-components-theme($theme);
    @include zonar-ui-footer-component-theme($theme);
    @include zonar-ui-sidenav-theme($theme);
}

.zonar-default-theme {
    @include apply-zonar-theme($zonar-default-theme);
    background-color: white;
}

.zonar-dark-theme {
    @include apply-zonar-theme($zonar-dark-theme);
    background-color: black;
}

// @import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
$primaryColor: #3e464c;
$primaryDarkColor: #2b3135;
$primaryLightColor: #777d81;
$accentColor: #5180ce;
$accentDarkColor: #2d8ed9;
$accentLightColor: #86b0f5;
$accentTextColor: #ffffff;
$darkMenuBgColor: #2b3135;
$darkMenuHoverColor: #777d81;
$darkMenuRouterLinkActiveColor: #5180ce;
$lightMenuRouterLinkActiveColor: #5180ce;
$horizontalLightMenuRouterLinkActiveColor: #5180ce;

@import 'assets/sass/layout/_layout';

$primaryColor: #2D8ED9;
$primaryDarkColor: #2b3135;
$primaryLightColor: #777d81;
$accentColor: #5180ce;
$accentDarkColor: #2d8ed9;
$accentLightColor: #86b0f5;
$accentTextColor: #ffffff;

@import 'assets/sass/theme/_theme';



.layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul {
  top: 38px !important;
}

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    background-color: $zcWhite;
}

.ghost-background {
  background-color: $zcGhost;
}

.cdk-global-scrollblock{
	position: static !important;
	width: initial !important;
	overflow-y: inherit !important;
}

.highchart-container {
  background-color: #fff;
}
.autocomplete-wrapper {
  .mat-button-toggle-group {
    margin-right: 15px;
  }
  .mat-button-toggle {
    .mat-button-toggle-label-content {
      line-height: 20px;
    }
    height: 20px;
  }
}

// Custom styles for user-menu on header component
.user-menu {
  &.mat-menu-panel {
    max-width: 20rem;
    min-width: 20rem;
    margin-top: 0.4em;
    padding: 25px;
    background-color: $zcWhite;
    overflow: auto;
    z-index: 1000;
    top:-15px;
  }

  .mat-menu-item  {
    height: auto;
    text-align: right;
    color: $zcBlack;
    margin-right:12px;
    font-size:14px;
    line-height: 19px;
    font-family: 'Open Sans', sans-serif !important
  }
}

// Custom styles for autocomplete on dispatcher list component
.dispatcher-autocomplete {
  .mat-form-field-infix {
    border-top: 0;
  }
  .mat-form-field-wrapper {
    padding-bottom: 0;
    .mat-form-field-flex {
      padding: 0.5em;
      .mat-form-field-infix {
        padding: 0.5em;
      }
      .mat-form-field-suffix {
        .mat-icon-button {
            height: 1em;
            width: 1em;
        }
      }
    }
    .mat-form-field-underline {
      bottom: 0;
      &::before {
        background-color: #fff;
      }
    }
    .mat-form-field-ripple {
      background-color: #fff;
    }
    .mat-input-element {
      caret-color: #fff;
      &::placeholder {
        color: #fff;
      }
    }
  }
}

// Overlay css when sidebar opens
.z-sidebar-overlay {
  //position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  user-select: none;
  animation-name: modal-in;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.05, 0.74, 0.2, 0.99);
  animation-fill-mode: forwards;
}

/** Select dropdown custom styles **/
@mixin custom-select-panel-css($width) {
  .cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
    width: $width;
  }
}

$default-dropdown-field-width: 200px;

.default-width-select-panel {
  min-width: $default-dropdown-field-width !important;
  width: $default-dropdown-field-width;
  margin-left: 2em;
  @include custom-select-panel-css($default-dropdown-field-width);
}
.default-width-no-checkboxes {
  @include custom-select-panel-css($default-dropdown-field-width);
  min-width: $default-dropdown-field-width !important;
  width: $default-dropdown-field-width;
  margin-left: 0;
}

.driver-dropdown-select-panel {
  @include custom-select-panel-css($default-dropdown-field-width);
}


.progress-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}


// Generic Filter container css
.zui-filters-container, .default-width-select-panel .contains-mat-select-search {
  mat-icon {
    margin-top: 0;
    top: 12px;
  }
}

.mat-chip {
  mat-icon {
    margin-top: 0;
    top: 1px;
  }
}

.default-width-select-panel .contains-mat-select-search .mat-select-search-input {
  padding-left: 24px;
}

// dispatcher-list-entity selection drop down, generic drop down
.cdk-overlay-pane {
  .mat-icon {
    margin: 0;
  }
  .mat-select-panel {
    .mat-select-search-input {
      padding-left: 25px;
    }
  }
}


// .default-width-select-panel {
//   .contains-mat-select-search {
//     mat-icon {
//       margin-top: 0;
//       top: 1px;
//     }
//   }
// }
// }

//Fixes the background color for the sidenav content
.mat-sidenav-content {
  background-color: $zcGhost;
}

//Hides the sidenav based on the returning params
.hideSidenav {
  .sidenav-inner-container{
    display: none !important;
  }
}

